export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#e5017d', '#e5017d'],
        home_question: ['#e5017d', '#e5017d'],
        applausometro: ['#e5017d', '#e5017d'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#EA4D95', '#E76E51'],
        qna: ['#e5017d', '#e5017d'],
    },
    primary: '#EA4D95',
    secondary: '#263238',
    accent: '#EA4D95',
    text: '#eceff1',
    background:"#134071",
    poll:{
        default:"#52b9e6",
        answered:"#e5017d",
        correct: '#6de652',
        wrong: '#e65252'
    }
}